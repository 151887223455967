import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from 'next/app';
import Router from 'next/router';
import { SnackbarProvider } from 'notistack';
import { StripeProvider } from 'react-stripe-elements';
import { DefaultSeo } from 'next-seo';
import theme from '../src/theme/elevator_theme';
import bugsnagClient from '../src/lib/errors/bugsnag';
import Error from './_error';
import { initGA, logPageView } from '../src/lib/googleAnalytics';

const ErrorBoundary = bugsnagClient.getPlugin('react');

class NextApp extends App {
    constructor() {
        super();
        this.state = { stripe: null };
    }

    componentDidMount() {
        if (window) {
            window.location.replace(process.env.cablecarBaseRedirect + '/submit');
        }

        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

        if (!window.GA_ANALYTICS && process.env.cookieDomain === 'elevatormag.com') {
            initGA();
            window.GA_ANALYTICS = true;
            logPageView(window.location.pathname);
            Router.events.on('routeChangeComplete', logPageView);
        }

        this.setState({ stripe: window.Stripe(process.env.stripePublicKey) });
    }

    render() {
        const { Component, pageProps } = this.props;

        return (
            <ErrorBoundary FallbackComponent={Error}>
                <React.Fragment>
                    <DefaultSeo
                        title={'ELEVATOR'}
                        description={
                            'The dopest new hip hop, upcoming artsits, music news, culture, and style'
                        }
                        openGraph={{
                            type: 'website',
                            url: 'https://submissions.elevatormag.com',
                            title: 'ELEVATOR Submissions',
                            description: 'ELEVATOR Submission Platform',
                            images: [
                                {
                                    url:
                                        'https://elevator-media.imgix.net/static/SEO_banner.png?w=400&h=300&ixlib=js-2.1.2&s=5b3e04cf0d4128c3877724435a3b26d3',
                                    width: 400,
                                    height: 300,
                                    alt: 'ELEVATOR Submissions banner',
                                },
                            ],
                        }}
                        twitter={{
                            site: '@ELEVATOR_',
                            cardType: 'app',
                        }}
                    />
                    <StripeProvider stripe={this.state.stripe}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider
                                maxSnack={3}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                                <CssBaseline />
                                <Component {...pageProps} />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StripeProvider>
                </React.Fragment>
            </ErrorBoundary>
        );
    }
}

export default NextApp;
