import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize('UA-33501668-2');
};

export const logPageView = (url) => {
    ReactGA.set({ page: url });
    ReactGA.pageview(url);
};

export const logEvent = (event) => {
    ReactGA.event(event);
};
